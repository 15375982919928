<template>
  <div>
    <div class="header">
      <div class="container">
        <div class="row">
          <div class="col-2" @click="informationernaehrungzuinformation()">
            <img src="../assets/icons/vector_back.svg">
            <!-- wenn kein Icon, img entfernen -->
          </div>
          <div class="col-8 col-center">
            <p class="pbolduppercase fontcolor-white">Medikation</p>
          </div>
          <div class="col-2 col-right">
            <!-- wenn kein Icon, img entfernen -->
          </div>
        </div>
      </div>
    </div>

    <div class="accordion-body margintop140 container marginbottom20">
      <p class="pregular fontcolor-white opacity80">Hier ein Überblick der einzunehmenden Medikamente in Kategorien geordnet:</p>
    </div>

    <div class="container paddingbottom110">
      <div class="accordion" role="tablist">
        
        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button block v-b-toggle.accordion-1 variant="info">
              <p class="psemibold fontcolor-white">
                Immunsuppressiva
              </p>
            </b-button>
          </b-card-header>
          <b-collapse id="accordion-1" accordion="my-accordion" role="tabpanel">
            <b-card-body>
              <p class="pregular fontcolor-white">
                ... hemmt die Abwehrreaktion des Körpers gegen die transplantierte Niere
                <br><br>
                Die Aufgabe unseres Immunsystems ist es, körperfremdes Eiweiß zu erkennen und zu vernichten. Daher wird Ihr Immunsystem beim ersten Kontakt zwischen Ihrem Blut und dem Gewebe der transplantierten Niere erkennen, dass das Transplantat fremd ist und versuchen, das Organ abzustoßen. Medikamente zur Immunsuppression verhindern, dass Ihr Körper das transplantierte Organ abstößt. Sie sollen die Fähigkeit des Immunsystems verringern, das fremde Gewebe zu attackieren.
                <br><br>
                Beispiele<br>
                Prograf ® – Wirkstoff: Tacrolimus<br>
                Advagraf ® – Wirkstoff: Tacrolimus<br>
                Sandimmun ® – Wirkstoff: Cyclosporin<br>
                Decortin ® ¬– Wirkstoff: Prednisolon<br>
                CellCept ® – Wirkstoff: Mycophenolat<br>
                Myfortic ® – Wirkstoff: Mycophenolat<br>
              </p>
            </b-card-body>
          </b-collapse>
          <div></div>
        </b-card>
        
        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button block v-b-toggle.accordion-2 variant="info">
              <p class="psemibold fontcolor-white">
                Diuretika
              </p>
            </b-button>
          </b-card-header>
          <b-collapse id="accordion-2" accordion="my-accordion" role="tabpanel">
            <b-card-body>
              <p class="pregular fontcolor-white">
                … fördern die Urinausscheidung
                <br><br>
                Beispiele<br>
                Lasix ® – Wirkstoff: Furosemid<br>
                Torem ® – Wirkstoff: Torasemid<br>
                Esidrix ® – Wirkstoff: Hydrochlorothiazid<br>
              </p>
            </b-card-body>
          </b-collapse>
        </b-card>

        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button block v-b-toggle.accordion-3 variant="info">
              <p class="psemibold fontcolor-white">
                Infektionsprophylaxe
              </p>
            </b-button>
          </b-card-header>
          <b-collapse id="accordion-3" accordion="my-accordion" role="tabpanel">
            <b-card-body>
              <p class="pregular fontcolor-white">
                … beugt möglichen Infektionen vor
                <br><br>
                Beispiele<br>
                Kepinol forte ® – Wirkstoff: Cotrimoxazol (Schmalspur-Antibiothikum gegen eine bestimmte Sorte gefährlicher Lungenentzündungen)<br>
                Valcyte ® – Wirkstoff: Valganciclovir (gegen bestimmte Viren und vor allem gegen CMV)<br>
                Candio Hermal ® – Wirkstoff: Nystatin (gegen verschiedene Pilze)<br>
              </p>
            </b-card-body>
          </b-collapse>
        </b-card>

        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button block v-b-toggle.accordion-4 variant="info">
              <p class="psemibold fontcolor-white">
                Antihypertensiva
              </p>
            </b-button>
          </b-card-header>
          <b-collapse id="accordion-4" accordion="my-accordion" role="tabpanel">
            <b-card-body>
              <p class="pregular fontcolor-white">
                ... regulieren den Blutdruck
                <br><br>
                Beispiele<br>
                Delix ® – Wirkstoff: Ramipril<br>
                Norvasc ® – Wirkstoff: Amlodipin<br>
                Beloc zok ® – Wirkstoff: Metoprolol<br>
                Clonidin ® – Wirkstoff: Clonidin<br>
                Diblocin ® – Wirkstoff: Doxazosin<br>
              </p>
            </b-card-body>
          </b-collapse>
        </b-card>

        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button block v-b-toggle.accordion-5 variant="info">
              <p class="psemibold fontcolor-white">
                Magen-Ulcusprophylaxe
              </p>
            </b-button>
          </b-card-header>
          <b-collapse id="accordion-5" accordion="my-accordion" role="tabpanel">
            <b-card-body>
              <p class="pregular fontcolor-white">
                ... schützt den Magen vor möglichen Nebenwirkungen durch Medikamente (Gastritis)
                <br><br>
                Beispiele<br>
                Pantozol ® – Wirkstoff: Pantoprazol<br>
                Nexium ® – Wirkstoff: Esomeprazol<br>
              </p>
            </b-card-body>
          </b-collapse>
        </b-card>

        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button block v-b-toggle.accordion-6 variant="info">
              <p class="psemibold fontcolor-white">
                Thromboseprophylaxe
              </p>
            </b-button>
          </b-card-header>
          <b-collapse id="accordion-6" accordion="my-accordion" role="tabpanel">
            <b-card-body>
              <p class="pregular fontcolor-white">
                ... beugt Blutgerinnseln vor
                <br><br>
                Beispiele<br>
                Clexane ® – Wirkstoff: Enoxaparin<br>
                Heparin ® – Wirkstoff: Heparin<br>
              </p>
            </b-card-body>
          </b-collapse>
        </b-card>
        <!-- <information-accordion accordiontitle="Vitalwerte" accordiontext="Loremipsumdolorsit"></information-accordion> -->
      </div>
    </div>
    <foot-navigation></foot-navigation>
  </div>
</template>

<script>
  import router from '../router';
  import FootNavigationVue from "../components/FootNavigation.vue";

  export default {
    components: {
      'foot-navigation' :FootNavigationVue,
    },
    methods: {
      informationernaehrungzuinformation() {
        router.push({ path: '/information' })
      },
    },
   }; 
</script>

<style>
.accordion {
    width: 100%;
    color: #ffffff;
    cursor: pointer;
    border-top: 1px solid rgba(255, 255, 255, 0.5) !important;
    text-align: left;
}

.accordion .card:first-of-type {
    border-bottom: 1px solid rgba(255, 255, 255, 0.5) !important;
}

.accordion .card:not(:first-of-type):not(:last-of-type) {
    border-bottom: 1px solid rgba(255, 255, 255, 0.5) !important;
}

.mb-1, .my-1 {
    margin-bottom: 0.25rem!important;
}

.p-1 {
    padding: 0rem!important;
}

.card {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: transparent !important;
    background-clip: border-box;
    border-radius: 0rem;
    border-bottom: 1px solid rgba(255, 255, 255, 0.5) !important;
}

.card-header:first-child {
    border-radius: 0 !important;
}

.card-header {
    padding: 0.75rem 0rem;
    margin-bottom: 0;
    background-color: rgba(0,0,0,.03);
    border-bottom: 1px solid rgba(0,0,0,.125);
}

.btn {
    background: transparent;
    border: 0;
    outline: none;
    border-radius: 30px;
    display: inline-block;
    font-weight: 400;
    text-align: left;
    white-space: nowrap;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: 1px solid transparent;
    padding: 1.3rem 0rem;
    font-size: 1rem;
}

.btn-block {
    display: block;
    width: 100%;
}

.btn-info {
    color: #fff;
    background-color: transparent !important;
    border-color: transparent !important;
}

.btn-info:not(:disabled):not(.disabled).active:focus, .btn-info:not(:disabled):not(.disabled):active:focus, .show>.btn-info.dropdown-toggle:focus {
    box-shadow: none !important;
}

.btn-info.focus, .btn-info:focus {
    box-shadow: none !important;
}

.card-body {
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    padding: 0rem 0rem 1.5rem 0rem;
}

</style>